<template>
  <div class="page">
    <div class="title">{{newsInfo.title}}</div>
    <div class="aboutinfo">
      <span class="author" v-if="newsInfo.author">{{newsInfo.author}}</span>
      <span class="time" v-if="newsInfo.createTime">{{newsInfo.createTime.split("T")[0]+' '+newsInfo.createTime.split("T")[1]}}</span>
    </div>
    <div class="content" v-html="newsInfo.content"></div>
  </div>
</template>

<script type="text/ecmascript-6">
import {getNewsInfoList,getNewsInfo} from '../../network/apiWebSite'

export default {
  name: "",
  data() {
    return {
      newsInfo: {}
    }
  },
  mounted() {
    this.getNewsInfoList()
  },
  methods: {
    // 网络请求
    getNewsInfoList() {
      getNewsInfoList().then(res=>{
        console.log(res)
        if(res.status===200&&res.data.newsList.length>0) {
          const firstId = res.data.newsList[0].id
          this.getNewsInfo(firstId)
        }
      })
    },
    getNewsInfo(id) {
      getNewsInfo(id).then(res=>{
        console.log(res)
        if(res.status===200&&res.data) {
          this.newsInfo = res.data
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.page {
  padding: 40px 40px 0px 40px;

  .title {
    font-size: 44px;
    line-height: 1.4;
    margin-bottom: 28px;
    margin-top: 0;
  }
  .aboutinfo {
    font-size: 28px;
    margin-bottom: 64px;

    .author {
      margin-right: 20px;
      color: #576b95;
    }
    .time {
      color: rgba(0, 0, 0, .3);
      margin-right: 20px;
    }
  }
  .content {
    font-size: 34px;
    line-height: 1.5;

    a {
      color: #576b95!important;
    }
  }
}
</style>
